import { createStore, applyMiddleware } from 'redux';

import rootReducer from '../reducers';
import rootSaga from '../sagas';
import middlewares, { sagaMiddleware } from './middlewares';

export default initialState => {
    const store = createStore(
        rootReducer(),
        initialState,
        applyMiddleware(...middlewares)
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
